.navbar {
  position: absolute;
  width: 360px;
  height: 76px;
  left: 0px;
  top: 0px;
  /* background-color: aqua; */
}
.status_bar {
  position: absolute;
  width: 100vw;
  height: 20px;
  left: 0px;
  top: 0px;

  background: rgba(0, 0, 0, 0.12);
}

.top_nav {
  position: absolute;
  width: 100vw;
  height: 56px;
  left: 0px;
  top: 20px;
  display: flex;
  align-items: center;
  background: #f6f6f6;
}
.ellipse {
  position: absolute;
  width: 36px;
  height: 36px;
  left: 16px;
  top: 28px;
  border-radius: 360px;
  background: #ffcda8;
  border: 4px solid #f6f6f6;
}

.imaging_center {
  position: absolute;

  /*here , i had fixed the width of imaging center , due to this , center name was not displaying properly    */
  /* width: 192px; */
  height: 20px;
  left: 72px;
  top: 20px;
  text-transform: capitalize;

  font-family: inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 5px;

  /* identical to box height */

  color: rgba(57, 129, 233, 0.8);
}


.upload_info_container{
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
}

.upload_image_container{

}

.done_img {
    
    width: 100%;
   
  }

  .success {
    display: flex;
    justify-content: center;
    color: rgba(0, 0, 0, 0.76);
    font-family: inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .done {
   
  
    color: rgba(0, 0, 0, 0.76);
    font-family: inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
  }

  .upload_error_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
  }

  .file_process_error{
    padding: 20px;
  }
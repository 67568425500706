.capture {
  position: absolute;
  width: 124px;
  height: 36px;
  left: 38px;
  top: 396px;
  outline: none;

  background: #ffffff;
  border: 2px solid rgba(227, 208, 255, 0.36);
  box-shadow: 0px 4px 12px rgba(57, 129, 233, 0.24);
  border-radius: 80px;
}
.capture:hover,
.capture:active {
  outline: none;
}
.capture__label:hover,
.capture__label:active {
  outline: none;
}
.capture_text {
  position: absolute;
  width: 49px;
  height: 16px;
  left: 86px;
  top: 406px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;

  color: rgba(0, 0, 0, 0.76);
}

.camera {
  position: absolute;
  width: 22px;
  height: 22px;
  left: 52px;
  top: 403px;
}

.upload {
  position: absolute;
  width: 124px;
  height: 36px;
  left: 198px;
  top: 396px;
  /* border: none; */
  outline: none;

  background: #ffffff;
  border: 2px solid rgba(227, 208, 255, 0.36);
  box-shadow: 0px 4px 12px rgba(57, 129, 233, 0.24);
  border-radius: 80px;
}

.midpart {
  position: absolute;
  width: 200px;
  height: 152px;
  left: 79px;
  top: 116px;
  /* background-color: aqua; */
}

div:first-child {
  /* position: absolute; */
  /* width: 153px;
height: 20px;
left: 16px;
top: 320px;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 20px;
/* identical to box height */

  /* color: rgba(0, 0, 0, 0.76);  */

  /* border: 1px solid #000000; */
}

.submit_doc {
  position: absolute;
  width: 153px;
  height: 20px;
  left: 16px;
  top: 320px;

  font-family: inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.76);

  /* border: 1px solid #000000; */
}

.doc {
  position: absolute;
  width: 262px;
  height: 16px;
  left: 16px;
  top: 348px;

  font-family: inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.76);
}

.upload_pic {
  /* position: absolute;
  width: 20px;
  height: 20px;
  left: 243px;
  top: 404px;
  font-size: 5px;

  transform: matrix(-1, 0, 0, 1, 0, 0); */
}
